import * as Sentry from '@sentry/nextjs';
import { NextPage } from 'next';
import NextErrorComponent, { ErrorProps } from 'next/error';
import React from 'react';

import captureException from '../utils/capture-exception';

const MyError: NextPage<{
    statusCode: number;
    hasGetInitialPropsRun?: boolean;
    err?: string;
}> = ({ statusCode, hasGetInitialPropsRun, err }) => {
    if (!hasGetInitialPropsRun && err) {
        captureException(err);
    }

    return <NextErrorComponent statusCode={statusCode} />;
};

MyError.getInitialProps = async ({ res, err, asPath }) => {
    const errorInitialProps: ErrorProps & { hasGetInitialPropsRun?: boolean } =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await NextErrorComponent.getInitialProps({ res, err });

    errorInitialProps.hasGetInitialPropsRun = true;

    if (err) {
        captureException(err);

        await Sentry.flush(2000);

        return errorInitialProps;
    }

    captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`));

    await Sentry.flush(2000);

    return errorInitialProps;
};

export default MyError;
